export const INTRODUCER_ATTRIBUTE_TYPE = 'user';

export const INPUT_TYPES = {
  button: 'button',
  text: 'text',
  number: 'number',
  percent: 'percent',
  address: 'address',
  propertyAddress: 'propertyAddress',
  date: 'date',
  wheel: 'wheel',
  textarea: 'textarea',
  checkbox: 'checkbox',
  radio: 'radio',
  pensionProvider: 'pensionProvider',
  email: 'email',
  phoneNumber: 'phoneNumber',
  nin: 'nin',
  select: 'select',
  infoButton: 'infoButton',
  groupParams: 'groupParams',
  groupParamsMarketingList: 'groupParamsMarketingList',
  dataset: 'dataset',
  segmentedButton: 'segmentedButton',
  introducer: 'introducer',
  introducerAutocomplete: 'introducerAutocomplete',
  priorityGoalSelect: 'priorityGoalSelect',
  updateUser: 'updateUser'
};

export const INPUT_INFORMER_TYPES = {
  modal: 'modal',
  static: 'inline'
};
